import { graphql, useStaticQuery } from 'gatsby';
import React from "react";

import "./side-posts.scss"

export default function SidePosts() {
    const data = useStaticQuery(query);
    const posts = data.allWpPost.edges;
    
    return (
      <div className="side-post">
        {posts.map((post, index) => {
            return <div className="post-container">
                      {post.node.featuredImage && (
                          <div className="post-img" style={{ backgroundImage: `url(${post.node.featuredImage.node.sourceUrl})` }} />
                      )}
                      <a className="post-link" href={`/strefa-wiedzy/${post.node.slug}/`} title={post.node.title}>
                        {post.node.title}
                      </a>
                    </div>
        })}
      </div>
    );
}

const query = graphql`
  {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Strefa wiedzy"}}}}}
      sort: {fields: date, order: ASC}
      limit: 3
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
        }
      }
    }
  }
`


