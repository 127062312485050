import React from 'react'
import Layout from '../components/Layout/Layout'
import Navigation from '../components/Navigation/Navigation'
import Seo from '../components/Seo/Seo'
import SidePosts from '../components/SidePosts/SidePosts';
import Posts from '../components/Posts/Posts';
import { getImage } from 'gatsby-plugin-image'
import config from '../../config.json';
import '../scss/pages/post.scss';
import StickyBox from '../components/StickyBox/StickyBox';
import Avatar from '../assets/images/avatar.png';
import ReferrerLink from '../components/ReferrerLink/ReferrerLink';

export default function Post({pageContext}) {
    const heading = pageContext.data.title
    const content = pageContext.data.content
    const seo = pageContext.data.seo
    const post = pageContext.data
    const next = pageContext.next
    const previous = pageContext.previous
    const postImage = getImage(post.featuredImage.node.localFile)
    
    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl}/>
            <div className="post-page">
                <div className="header">
                    <Navigation/>
                    <div className="custom-container">
                        <h1 class="text-center mt-50 mb-50 title">{heading}</h1>
                        <ReferrerLink activeClass="cta-radius referrer-link" text="Dopasuj ubezpieczenie" />
                    </div>
                </div>
                <div className="custom-container post-content-container">
                    <div className="row">
                        <div class="col-lg-8">
                            <img className="post-image" src={postImage.images.fallback.src} title={heading} alt={heading} />
                            <p className="clip">{post.strefaWiedzy.zajawka}</p>

                            {post.strefaWiedzy.czasCzytania && (
                                <span id="read-time">{post.strefaWiedzy.czasCzytania}</span>
                            )}

                            <div class="post-content" dangerouslySetInnerHTML={{ __html: content }}></div>
                            
                            <div class="author">
                                <div className="row">
                                    <div className="col-md-2">
                                        {Boolean(post.author.node.avatar.url) ? (
                                            <img className="author-image" src={post.author.node.avatar.url} title="autor" alt="autor" />
                                        ) : (
                                            <img class="author-image" src={Avatar} alt="autor" title="autor" />
                                        )}
                                    </div>
                                    <div className="col-md-10">
                                        <p className="author-name">{post.author.node.firstName + ' ' +post.author.node.lastName}</p>
                                        <p className="description">Redaktor portalu UbezpieczeniePracowników.pl</p>
                                        <p className="user-description">{post.author.node.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row articles-link">
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    {next && (
                                        <a className="prev-article" title={next.title} href={`/strefa-wiedzy/${next.slug}`}>
                                            Poprzedni artykuł
                                        </a>
                                    )}
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    {previous && (
                                        <a className="next-article" title={previous.title} href={`/strefa-wiedzy/${previous.slug}`}>
                                            Następny artykuł
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <SidePosts />
                            <StickyBox />
                        </div>
                    </div>
                </div>
                <Posts content="Już od wielu lat zajmujemy się zarówno samą tematyką ubezpieczeniową, jak i skuteczną komunikacją pomiędzy klientami, a agentami i towarzystwami ubezpieczeniowymi. Ubezpieczenia grupowe pracowników to obszerna dziedzina. Dokładamy starań, aby poszczególne składające się na nią zagadnienia przybliżać w przystępny i zarazem wyczerpujący sposób. Zarówno pracownikom, jak i pracodawcom poszukującym rozwiązań, dzięki którym zyskają w ich oczach ważny atut. Zapraszamy do częstych odwiedzin na naszym blogu."/>
            </div>
        </Layout>
    )
}
