import React from 'react'
import Sticky from "react-sticky-box";
import LongArrow from '../../assets/images/icons/arrow-long.svg'
import "./sticky.scss"
import ReferrerLink from '../ReferrerLink/ReferrerLink';

export default function StickyBox() {
  return (
    <Sticky offsetTop={20} offsetBottom={50}>
      <div id="sticky-box">
        <div className="top-line"></div>
        <h3 class="title">Sprawdź<br />najlepsze<br />ubezpieczenie</h3>
        <div className="cta-btns-group pt-30">
          <ReferrerLink activeClass="cta-radius referrer-link" title="Dopasuj ubezpieczeni" text="Dopasuj ubezpieczenie" />
          <a href="/ranking-ubezpieczen/" className="cta">Sprawdź ranking <img className="arrow" src={LongArrow} alt="Sprawdź ranking" title="Sprawdź ranking" /></a>
        </div>
      </div>
    </Sticky>
  )
}
